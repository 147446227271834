import React, { useMemo } from 'react';

import Carousel from 'components/uiLibrary/Carousel';
import AdvertisementRenderer from 'components/Globals/AdvertisementBlock/AdvertisementRenderer';
import { SECTIONS } from 'components/Globals/Analytics/constants';

import useCampaignAttributionDetails from 'utils/hooks/useCampaignAttributionDetails';
import getAdvertisements, {
  getAdvertisementConfigs,
  AD_IDS,
  AD_IDENTIFIER_TYPE,
  AD_PLACEMENTS,
  AD_TAGS,
} from 'utils/advertisements';

import classes from './MainBackgroundBanners.module.scss';

const MainBackgroundBanners = () => {
  const { utmContent } = useCampaignAttributionDetails();
  const adConfigs = useMemo(() => {
    let ads = [];
    if (utmContent) {
      ads = getAdvertisements(utmContent, [AD_PLACEMENTS.BANNER])?.[AD_PLACEMENTS.BANNER];
    }

    if (!ads?.length) {
      const firstBanner = getAdvertisementConfigs({
        identifiers: [AD_TAGS.OPERABASE],
        identifierType: AD_IDENTIFIER_TYPE.TAG,
        placement: AD_PLACEMENTS.BANNER,
      });

      const otherBanners = getAdvertisementConfigs({
        identifiers: [AD_IDS.OB018, AD_IDS.OB012],
        identifierType: AD_IDENTIFIER_TYPE.ID,
        placement: AD_PLACEMENTS.BANNER,
      });

      ads = [...firstBanner, ...otherBanners];
    }

    return ads;
  }, [utmContent]);

  return (
    <Carousel
      options={{
        maxVisibleItems: 1,
        styles: { rightArrow: classes.rightArrow, leftArrow: classes.leftArrow, root: classes.carousel },
        autoScroll: true,
      }}
    >
      {adConfigs?.map((config, index) => (
        <AdvertisementRenderer key={index} config={config} section={SECTIONS.HERO_BANNER} />
      ))}
    </Carousel>
  );
};

export default MainBackgroundBanners;

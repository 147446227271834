import React from 'react';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { cueTvMediaUrlRegex, stagePlusMediaUrlRegex } from '../../../constants/consts';
import { route } from '../../../constants/routes';
import classes from './LsVodTargetLink.module.scss';

const LsVodTargetLink = ({ media, partnerSource, url, title }) => {
  const handlerClick = e => {
    e.preventDefault();

    const isLive = media?.lsVodData?.isLive;
    if (!isLive) {
      return initiateExternalRedirect();
    }

    // check if the url is a cuetv url
    if (url?.match(cueTvMediaUrlRegex)) {
      return initiateExternalRedirect();
    }

    if (url?.match(stagePlusMediaUrlRegex)) {
      return initiateExternalRedirect();
    }
  };

  const initiateExternalRedirect = () => {
    window.open(`${route.LINK}?url=${url}&name=${title}&partner_source=${encodeURIComponent(partnerSource)}`, '_blank');
  };

  return (
    <span className={classes.icon}>
      <LaunchOutlinedIcon onClick={e => handlerClick(e)} />
    </span>
  );
};

export default LsVodTargetLink;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useRef, useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import Tooltip from 'components/uiLibrary/Tooltip';
import Typography from 'components/uiLibrary/Typography';
import Modal from 'components/uiLibrary/Modal';

import queries from 'containers/Globals/queries';

import ImpressionTracker from 'utils/components/impressionTracker';
import { TP, ENTITY_TYPE } from 'constants/index';
import { useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester';
import useTracking from 'components/Globals/Analytics';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import classes from './Testimonials.module.scss';

const trackingData = {
  section: SECTIONS.TESTIMONIALS,
  component: COMPONENTS.TESTIMONIAL_CARD,
};

const modalTrackingData = {
  section: SECTIONS.TESTIMONIAL_MODAL,
};

const TestimonialCard = ({ data, inLine }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const leftSideRef = useRef(null);
  const rightSideRef = useRef(null);
  const track = useTracking();
  const trackingClasses = useTrackingClickStyles(trackingData);

  const [clampedLines, setClampedLines] = useState(1);
  const { user, userName, description, organization, userTitle, id } = data || {};
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const leftSideElement = leftSideRef.current;
    const rightSideElement = rightSideRef.current;

    const leftSideHeight = Math.max(leftSideElement.clientHeight, 40);
    const lineHeight = parseFloat(window.getComputedStyle(leftSideElement).lineHeight);

    const maxLines = Math.floor(rightSideElement.clientHeight / lineHeight);
    const actualLines = Math.ceil(leftSideHeight / lineHeight);

    setClampedLines(Math.min(actualLines, maxLines));
  }, []);

  return (
    <>
      <div
        className={classnames(classes.testimonialCard, trackingClasses)}
        onClick={() => {
          setIsOpen(true);
          track.click({
            ...trackingData,
            entityId: id,
            meta: { organizationId: organization?.id },
          });
        }}
      >
        <div>
          <div className={classes.testimonialInfo} ref={leftSideRef}>
            <ImpressionTracker
              data={{
                ...trackingData,
                entityId: organization?.id,
                entityName: organization?.name,
                entityType: ENTITY_TYPE.ORGANIZATION,
              }}
            >
              <Typography
                variant="p"
                size={inLine ? 12 : 14}
                weight={inLine ? 'bold' : 'medium'}
                className={classes.organizationName}
                {...(inLine && { truncate: true })}
              >
                {organization?.name}
              </Typography>
            </ImpressionTracker>
            <Typography variant="p" size={12} weight="medium" {...(inLine && { truncate: true })} color="orange">
              {user?.name || userName}
            </Typography>
            <Typography variant="p" size={12} className={classes.userTitle} {...(inLine && { truncate: true })}>
              {userTitle}
            </Typography>
          </div>
        </div>

        <div ref={rightSideRef}>
          <Tooltip disabled={clampedLines <= 1} title={description}>
            <Typography
              variant="q"
              size={12}
              className={classnames({ [classes.clamped]: clampedLines > 1, [classes.testimonialDescription]: inLine })}
              style={{ WebkitLineClamp: clampedLines }}
              color="secondary"
            >
              {description}
            </Typography>
          </Tooltip>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        styles={{ title: classes.modalTitle }}
        title={t(`${TP}.FN_CUSTOMER_SAYS`)}
        allowMobileDrawer
        className={classes.testimonialModal}
      >
        <div className={classes.testimonialModal__body}>
          <Typography variant="p" size={14} color="secondary" className={classes.testimonialModal__body_description}>
            {description}
          </Typography>

          <div className={classes.testimonialModal__body_otherText}>
            <ImpressionTracker
              data={{
                ...modalTrackingData,
                entityId: organization?.id,
                entityName: organization?.name,
                entityType: ENTITY_TYPE.ORGANIZATION,
              }}
            >
              <Typography variant="p" size={14} weight="bold">
                {organization?.name}
              </Typography>
            </ImpressionTracker>
            <div>
              <Typography variant="p" size={14} weight="medium" color="orange">
                {user?.name || userName}
              </Typography>
              <Typography variant="p" size={14} color="secondary">
                {userTitle}
              </Typography>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const Testimonials = ({
  startOffset = 0,
  limit,
  hideTitle = false,
  inLine = false,
  italic = true,
  titleWeight = 'bold',
  className,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { data: testimonials } = useQuery(
    queries.getTestimonials({
      limit,
      queryConfig: {
        select: response => (limit > 0 ? response?.data.slice(startOffset, startOffset + limit) : response?.data),
      },
    }),
  );

  const testimonialsList = useMemo(
    () =>
      testimonials?.sort((a, b) => {
        const hasOrgA = a.organization && Object.keys(a.organization).length > 0;
        const hasOrgB = b.organization && Object.keys(b.organization).length > 0;

        if (hasOrgA && !hasOrgB) {
          return -1;
        }
        if (!hasOrgA && hasOrgB) {
          return 1;
        }
        return 0;
      }),
    [testimonials],
  );

  if (hideTitle) {
    return (
      <div
        className={classnames(classes.testimonials, {
          [classes.testimonialsHorizontal]: !!inLine,
          [className]: !!className,
        })}
      >
        {testimonials?.map(testimonial => (
          <TestimonialCard key={testimonial.id} data={testimonial} inLine={inLine} />
        ))}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="p" size={16} weight={titleWeight} align="center" italic={italic} secondaryFont>
        {t(`${TP}.LP_WHAT_CUSTOMERS_SAY`)}
      </Typography>
      <div className={classes.testimonials}>
        {testimonialsList?.map(testimonial => (
          <TestimonialCard key={testimonial.id} data={testimonial} />
        ))}
      </div>
    </div>
  );
};

export default memo(Testimonials);

import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Layout, { LayoutContent } from 'components/Layout';
import NextLink from 'components/NextLink';
import H1 from 'components/H1';
import { TP, RESPONSE_STATUS_CODES } from 'constants/index';
import { sentryCaptureMessage } from 'utils/globals/sentry';
import usePageContext from 'utils/hooks/usePageContext';

const codes = {
  301: {
    code: RESPONSE_STATUS_CODES.PERMANENT_REDIRECT,
    message: 'Moved Permanently!',
    content: 'The URL requested has been moved permanently.',
  },
  302: {
    code: RESPONSE_STATUS_CODES.TEMPORARY_REDIRECT,
    message: 'Temporary Redirect!',
    content: 'The URL requested has been moved temporarily.',
  },
  400: {
    code: RESPONSE_STATUS_CODES.BAD_REQUEST,
    message: 'Bad Request!',
    content: 'You have used invalid syntax.',
  },
  401: {
    code: RESPONSE_STATUS_CODES.UNAUTHORIZED,
    message: 'Unauthorized!',
    content: 'The URL requested requires authorization.',
  },
  403: {
    code: RESPONSE_STATUS_CODES.FORBIDDEN,
    message: 'Forbidden!',
    content: 'You are not permitted to access the requested URL.',
  },
  404: {
    code: RESPONSE_STATUS_CODES.NOT_FOUND,
    message: 'Not Found!',
    content: 'The requested URL was not found on this server.',
  },
  405: {
    code: RESPONSE_STATUS_CODES.METHOD_NOT_ALLOWED,
    message: 'Method Not Allowed!',
    content: 'The method used is not permitted.',
  },
  500: {
    code: RESPONSE_STATUS_CODES.INTERNAL_SERVER_ERROR,
    message: 'Internal Server Error!',
    content: 'The requested URL caused an internal server error.',
  },
  '500F': {
    code: '500',
    message: 'Page Error!',
    content: 'The requested URL caused an error on page.',
  },
  502: {
    code: RESPONSE_STATUS_CODES.BAD_GATEWAY,
    message: 'Bad Gateway!',
    content: 'This server received an invalid response from an upstream server it accessed to fulfill the request.',
  },
  503: {
    code: RESPONSE_STATUS_CODES.SERVICE_UNAVAILABLE,
    message: 'Service Unavailable!',
    content: 'The Service is not available at the moment due to a temporary overloading or maintenance of the server.',
  },
};

const ErrorPage = ({ statusCode, error }) => {
  const { entityType, entityId, language, paths } = usePageContext();
  const router = useRouter();

  const code = codes[statusCode] || codes['500F'];
  const customBreadcrumbs = [{ title: `${TP}.m_HOME` }];

  useEffect(() => {
    sentryCaptureMessage(code?.message, {
      extra: {
        statusCode: code?.code,
        entityType,
        entityId,
        language,
        paths,
        pathname: router.pathname,
        asPath: router.asPath,
      },
    });
  }, [code, entityType, entityId, language, paths, router.asPath, router.pathname]);

  return (
    <Layout customBreadcrumbs={customBreadcrumbs} title={`${code?.code} - ${code?.message}`}>
      <LayoutContent variant="regular">
        <div>
          <H1>{`${code?.code} - ${code?.message}`}</H1>
        </div>
        <div>
          <h3>The following error occurred:</h3>
          <p>{code?.content}</p>
          <p>
            Please <NextLink href="/contact">contact the administration</NextLink> with any queries.
          </p>
          <p>{process.env.APP_ENV && process.env.APP_ENV !== 'production' && error && error}</p>
        </div>
      </LayoutContent>
    </Layout>
  );
};

ErrorPage.propTypes = {
  statusCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ErrorPage;

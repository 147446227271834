import React, { Fragment, useMemo } from 'react';
import classnames from 'classnames';
import groupBy from 'lodash/groupBy';

import Image from 'components/uiLibrary/Image';
import LsVodLanguage from 'components/LsVod/LsVodLanguage';
import LsVodSubscription from 'components/LsVod/LsVodSubscription';
import LsVodTargetLink from 'components/LsVod/LsVodTargetLink';
import LsVodSource from 'components/LsVod/LsVodSource';
import Typography from 'components/uiLibrary/Typography';
import placeholder from 'public/images/icons/playIcon.png';
import { useTranslation } from 'src/i18n';
import { createDate } from 'utils/date';
import { getProductionTitle } from 'utils/productions';
import { TP, partnerType } from 'constants/index';
import { route } from 'constants/routes';
import { cueTvMediaUrlRegex } from 'constants/consts';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester/TrackingTester';

import classes from './ProductionWatchOptions.module.scss';

const LsVodInformation = ({ media, mediaInfo, title, trackingData }) => {
  const { partner, url, paymentType, cost, currency } = media?.attributes?.[0] || {};
  const partnerLogo = partnerType[partner?.name];
  const partnerSource = partnerLogo?.img ?? placeholder;
  const eventTime = media?.startTime;
  const eventZone = media?.timezone;
  const date = media?.startDate && `${createDate(media?.startDate).format('DD MMM, YYYY')}`;
  const time = `${eventTime || ''} ${eventZone || ''}`.trim();
  const isLiveToday = media?.lsVodData?.liveToday;
  const track = useTracking();
  const trackingClasses = useTrackingClickStyles(trackingData);

  const handlerClick = e => {
    e.preventDefault();
    const isLive = media?.lsVodData?.isLive;

    track.click({
      ...trackingData,
      meta: { url, date, title, isLive },
    });

    if (!isLive || url?.match(cueTvMediaUrlRegex)) {
      return window.open(
        `${route.LINK}?url=${url}&name=${title}&partner_source=${encodeURIComponent(partnerSource)}`,
        '_blank',
      );
    }

    return null;
  };
  return (
    <div className={classnames(classes.lsVodInformation, trackingClasses)} onClick={handlerClick}>
      <div className={classes.lsVodCompanyName}>
        <div className={classes.lsVodMediaWrapper}>
          <Image
            src={placeholder}
            disableCloudinary={!partnerLogo?.img}
            className={classes.icon}
            alt={partner?.name}
            width={44}
            height={44}
          />
        </div>
        <div className={classes.lsVodMainDetails}>
          <div className={classes.lsVodChannel}>
            <LsVodSource title={url} partner={partner?.name} isLive={isLiveToday} />
            {date && (
              <div className={classes.moreInfo}>
                <Typography color="secondary" size={12}>
                  {date}
                </Typography>
                {time && (
                  <Typography color="secondary" size={12} className={classes.time}>
                    {time}
                  </Typography>
                )}
                <LsVodSubscription paymentType={paymentType} cost={cost} currency={currency} />
              </div>
            )}
          </div>
          <LsVodLanguage production={mediaInfo} />
        </div>
        <LsVodTargetLink media={media} partnerSource={partnerSource} url={url} title={title} />
      </div>
    </div>
  );
};

const defaultTrackingData = {
  section: SECTIONS.PRODUCTION_WATCH_OPTIONS,
};

const ProductionWatchOptions = ({ entity, trackingData = defaultTrackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const title = getProductionTitle(entity);
  const modes = useMemo(() => groupBy(entity?.performances, 'mode'), [entity]);
  const vod = modes?.['digital-ls'];
  const liveStream = modes?.['digital-vod'];
  const mediaInfo = entity?.mediaInfo ?? {
    languages: entity?.languages,
    subtitles: entity?.subtitles,
  };

  if (!(liveStream?.length > 0 || vod?.length > 0)) {
    return null;
  }

  return (
    <>
      <Typography weight="bold" size="16">
        {t(`${TP}.FN_WATCH_NOW_ON`)}
      </Typography>
      <div className={classes.lsVodProductionDetail}>
        {liveStream?.length > 0 && (
          <div className={classnames(classes.lsVodProductionDetail__item, classes.lsVodProductionDetail__ls)}>
            {liveStream?.map((media, index) => (
              <LsVodInformation
                key={index}
                media={media}
                mediaInfo={mediaInfo}
                title={title}
                trackingData={{ ...trackingData, component: COMPONENTS.LIVE_STREAM }}
              />
            ))}
          </div>
        )}
        {vod?.length > 0 && (
          <div
            className={classnames(classes.lsVodProductionDetail__item, {
              [classes.lsVodProductionDetail__vod]: liveStream?.length > 0,
            })}
          >
            {vod?.map((media, index) => (
              <LsVodInformation
                key={index}
                media={media}
                mediaInfo={mediaInfo}
                title={title}
                trackingData={{ ...trackingData, component: COMPONENTS.VIDEO_ON_DEMAND }}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ProductionWatchOptions;

import { AD_IDS, AD_TAGS, AD_PLACEMENTS, AD_IDENTIFIER_TYPE, CONFIGURATIONS } from './constants';

export const getAdvertisementConfigs = ({
  identifiers = [],
  identifierType = AD_IDENTIFIER_TYPE.ID,
  placement = AD_PLACEMENTS.BANNER,
}) => {
  if (identifierType === AD_IDENTIFIER_TYPE.TAG) {
    return Object.values(CONFIGURATIONS).filter(
      config => config.placements?.includes(placement) && config.tags?.some(tag => identifiers.includes(tag)),
    );
  }

  if (identifierType === AD_IDENTIFIER_TYPE.ID) {
    return identifiers.reduce((acc, id) => {
      const matchedConfig = CONFIGURATIONS[id];
      if (matchedConfig && (!placement || matchedConfig.placements?.includes(placement))) {
        acc.push(matchedConfig);
      }

      return acc;
    }, []);
  }

  return [];
};

const getAdvertisements = (utmContent, placementFilter = []) =>
  utmContent?.split('-')?.reduce((acc, section) => {
    const [placement, ads] = section.split('!');
    if (placementFilter?.length && !placementFilter?.includes(placement)) {
      return acc;
    }

    const adsList = ads?.split('.') || [];

    const configs = adsList.reduce((configAcc, adId) => {
      const adIdentifierMatch = /(\w)(\w+)/.exec(adId);
      if (!adIdentifierMatch?.[2]) {
        return configAcc;
      }
      const adIdentifierType = adIdentifierMatch[1];
      const adIdentifierValue = adIdentifierMatch[2];

      if (adIdentifierType === AD_IDENTIFIER_TYPE.TAG) {
        configAcc.push(
          ...getAdvertisementConfigs({
            identifiers: [adIdentifierValue],
            identifierType: AD_IDENTIFIER_TYPE.TAG,
            placement,
          }),
        );
      } else if (adIdentifierType === AD_IDENTIFIER_TYPE.ID) {
        configAcc.push(
          ...getAdvertisementConfigs({
            identifiers: [adIdentifierValue],
            identifierType: AD_IDENTIFIER_TYPE.ID,
            placement,
          }),
        );
      }

      return configAcc;
    }, []);

    acc[placement] = configs;

    return acc;
  }, {}) || {};

export { AD_PLACEMENTS, AD_TAGS, AD_IDS, AD_IDENTIFIER_TYPE };
export default getAdvertisements;

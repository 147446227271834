import React, { useRef } from 'react';

import { COMPONENTS } from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';

import ImpressionTracker from 'utils/components/impressionTracker';
import { useUserData } from 'utils/hooks/useAuthenticatedUser';

const AdvertisementRenderer = ({ section, config, ...props }) => {
  const user = useUserData();
  const ref = useRef();
  const tracking = useTracking();
  const { component: Component, renderConfig } = config;
  const configuration = renderConfig ? renderConfig({ isTrialExhausted: user?.trialExhausted }) : {};

  if (!Component || !section) {
    return null;
  }

  const trackingData = {
    section,
    component: COMPONENTS.ADVERTISEMENT,
    meta: {
      id: config?.id,
      tags: config?.tags,
    },
  };

  const trackAdvertisementClick = e => {
    const { target } = e;
    if (['BUTTON', 'A'].includes(target.tagName)) {
      tracking.click({
        ...trackingData,
        subComponent: configuration?.subComponent,
        meta: {
          ...trackingData.meta,
        },
      });
    } else if (ref.current && ref.current.contains(e.target)) {
      tracking.click({
        ...trackingData,
        subComponent: configuration?.subComponent,
      });
    }
  };

  return (
    <ImpressionTracker data={trackingData} onClick={trackAdvertisementClick} skipEntityCheck>
      <div ref={ref}>
        <Component config={configuration} {...props} />
      </div>
    </ImpressionTracker>
  );
};

export default AdvertisementRenderer;

// TODO: FB-25982 Where to place this component?
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import Testimonials from 'components/Globals/Testimonials';
import CustomerLogos from 'components/Globals/CustomerLogos';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import MainBackgroundBanners from 'components/Globals/Banners/MainBackgroundBanners';

import classes from './BannerTestTrusted.module.scss';

const BannerTestTrusted = () => {
  const isTablet = useMediaQuery('(max-width:1024px)');

  return (
    <div className={classes.btt}>
      <div className={classes.btt__mainBanner}>
        <MainBackgroundBanners />
      </div>
      <div className={classes.btt__testimonials}>
        <HorizontalScrollIndicators
          styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
          triggerSize={0}
          isArrowsEnabled
        >
          <Testimonials hideTitle limit={10} inLine />
        </HorizontalScrollIndicators>
      </div>
      <div className={classes.btt__trustedBy}>
        <CustomerLogos
          className={classes.trustedByBlock}
          rowHeight={40}
          showTitle
          {...(isTablet && {
            allowShowAll: false,
            count: 20,
            hasLogosViewAll: true,
          })}
        />
      </div>
    </div>
  );
};

export default BannerTestTrusted;

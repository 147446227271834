import React from 'react';
import Typography from 'components/uiLibrary/Typography';
import { TP } from 'constants/index';
import { useTranslation } from 'src/i18n';
import classes from './LsVodSource.module.scss';

const LsVodSource = ({ title, partner, isLive, renderer }) => {
  const { t } = useTranslation();
  if (partner === 'CueTv') {
    return (
      <a className={classes.title} href="https://www.cuetv.online/" shallow title="CueTV" target="_blank">
        CueTV
      </a>
    );
  }

  let domainName = title;
  try {
    domainName = new URL(title).hostname?.replace('www.', '');
  } catch (err) {
    domainName = title?.replace('www.', '');
  }

  if (renderer) {
    return renderer({ partner, domainName, title, isLive, t });
  }

  return (
    <Typography className={classes.sourceName}>
      {partner || domainName || title}{' '}
      {isLive && <Typography className={classes.isLive}>{t(`${TP}.FN_LIVE`)}</Typography>}
    </Typography>
  );
};

export default LsVodSource;

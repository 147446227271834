import { get } from 'lodash';
import { defaultEntityParams, defaultListParams, entityReducer, listReducer } from '../../utils/crudUtils';
import {
  getValidationDocsAction,
  addValidationDocAction,
  deleteValidationDocAction,
  requestRedMaskAction,
} from './actions';
import { SET_PRODUCTION } from './constants';

export const key = 'Validation';

export const initialState = {
  production: defaultEntityParams,
  documents: defaultListParams,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PRODUCTION:
      return {
        ...state,
        production: { ...state.production, entity: payload?.production },
      };

    case getValidationDocsAction.TRIGGER:
    case getValidationDocsAction.FAILURE:
    case getValidationDocsAction.SUCCESS:
      return listReducer(state, type, payload, 'documents');

    case addValidationDocAction.SUCCESS:
      return {
        ...state,
        documents: { ...state.documents, data: [...state.documents.data, { ...payload?.entity, isNew: true }] },
      };

    case deleteValidationDocAction.SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          data: get(state, 'documents.data', []).filter(p => p?.id !== payload.id),
        },
      };

    case requestRedMaskAction.TRIGGER:
    case requestRedMaskAction.FAILURE:
      return entityReducer(state, type, payload, 'production');
    case requestRedMaskAction.SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          entity: { ...state.production.entity, validationStatus: payload.entity.validationStatus },
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default reducer;

import { defaultEntityParams, entityReducer, listReducer, namedListParams } from '../../utils/crudUtils';
import {
  getComposersListAction,
  getMWListAction,
  getProfessionsAction,
  getProfileAction,
  getRolesAction,
} from './actions';
import { RESET_LIST } from './constants';

export const key = 'AddRepertoire';

export const initialState = {
  profile: defaultEntityParams,
  professions: namedListParams('professions'),
  roles: namedListParams('roles'),
  mwList: namedListParams('mwList'),
  composers: namedListParams('composers'),
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getProfileAction.TRIGGER:
    case getProfileAction.SUCCESS:
    case getProfileAction.FAILURE:
      return entityReducer(state, type, payload, 'profile');

    case getProfessionsAction.TRIGGER:
    case getProfessionsAction.SUCCESS:
    case getProfessionsAction.FAILURE:
      return entityReducer(state, type, payload, initialState.professions.listName);

    case getRolesAction.TRIGGER:
    case getRolesAction.SUCCESS:
    case getRolesAction.FAILURE:
      return entityReducer(state, type, payload, initialState.roles.listName);

    case getMWListAction.TRIGGER:
    case getMWListAction.SUCCESS:
    case getMWListAction.FAILURE:
      return listReducer(state, type, payload, initialState.mwList.listName);

    case getComposersListAction.TRIGGER:
    case getComposersListAction.SUCCESS:
    case getComposersListAction.FAILURE:
      return listReducer(state, type, payload, initialState.composers.listName);

    case RESET_LIST: {
      const field = payload?.fieldName === 'composers' ? initialState.composers.listName : initialState.mwList.listName;
      return { ...state, [field]: namedListParams(field) };
    }

    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import head from 'lodash/head';
import Typography from 'components/uiLibrary/Typography';
import { TP } from 'constants/index';
import { useCurrencies } from '../../../utils/queries/currencies';
import { createSelectFieldOptions } from '../../../utils/common';
import { currencySymbols } from '../../../containers/Payment/constants';
import { useTranslation } from '../../../src/i18n';
import classes from './LsVodSubscription.module.scss';

const LsVodSubscription = ({ paymentType, cost, currency, styles = {} }) => {
  const { t } = useTranslation('NS_LS_VOD');
  const { data: currencies } = useCurrencies();
  const currencyOptions = createSelectFieldOptions(get(currencies, 'data', []), 'id', 'currencyCode', {}, true);
  const currencyId = currencyOptions?.find(lang => lang?.id === currency?.id);
  const currencyFilter = head(currencyOptions.filter(type => type?.id === currencyId?.id));
  const currencySymbol = currencySymbols[currencyFilter?.label];

  if (paymentType === 'free') {
    return (
      <Typography size={12} color="secondary" className={classnames(classes.content, styles.free)}>
        {t(`${TP}.FN_FREE`)}
      </Typography>
    );
  }

  if (paymentType === 'subscription' && cost) {
    return (
      <Typography color="secondary" size={12} className={classnames(classes.content, styles.paid)}>
        {t(`${TP}.m_FROM`)} {currencySymbol}
        {cost} {t(`${TP}.FN_ONWARDS`)}
      </Typography>
    );
  }

  if (paymentType === 'pay-per-view') {
    return (
      <Typography color="secondary" size={12} className={classnames(classes.content, styles.paid)}>
        {t(`${TP}.FN_PAY_PER_VIEW`)}
        {currencySymbol && ` ${currencySymbol}`}
        {cost}
      </Typography>
    );
  }

  return (
    <Typography color="secondary" size={12} className={classnames(classes.content, styles.empty)}>
      {t(`${TP}.FN_NO_SUBSCRIPTION`)}
    </Typography>
  );
};

export default LsVodSubscription;

import { createRoutine } from 'redux-saga-routines';
import { GET_VALIDATION_DOCS, SET_PRODUCTION, ADD_DOCUMENT, DELETE_DOCUMENT, REQUEST_RED_MASK } from './constants';

export const getValidationDocsAction = createRoutine(GET_VALIDATION_DOCS);
export const addValidationDocAction = createRoutine(ADD_DOCUMENT);
export const deleteValidationDocAction = createRoutine(DELETE_DOCUMENT);
export const requestRedMaskAction = createRoutine(REQUEST_RED_MASK);

export function setProduction(production) {
  return {
    type: SET_PRODUCTION,
    payload: { production },
  };
}

import { changeItemInObjectsArray } from 'utils/globals/app';
import { ENTITY_ACTION_TYPE } from 'constants/deprecate';
import { listReducer, namedListParams } from '../../utils/crudUtils';
import {
  createImagesAction,
  deleteImageAction,
  getEditImageProductionsAction,
  getImageFormatAttrTypesAction,
  getImagesAction,
  getImagesMediaAttrTypesAction,
  updateImageAction,
} from './actions';
import { CLEAR_IMAGES, reducerName } from './constants';

export const key = reducerName;

export const initialState = {
  images: namedListParams('images'),
  attributeTypes: namedListParams('attributeTypes'),
  imageFormats: namedListParams('imageFormats'),
  updatingEntityName: '',
  productions: namedListParams('productions'),
};

const editImagesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getImagesAction.TRIGGER:
    case getImagesAction.SUCCESS:
    case getImagesAction.FAILURE:
      return listReducer(state, type, payload, state.images.listName);

    case CLEAR_IMAGES:
      return { ...state, images: namedListParams('images') };

    case getImagesMediaAttrTypesAction.FAILURE:
    case getImagesMediaAttrTypesAction.TRIGGER:
    case getImagesMediaAttrTypesAction.SUCCESS:
      return listReducer(state, type, payload, state.attributeTypes.listName);

    case getImageFormatAttrTypesAction.FAILURE:
    case getImageFormatAttrTypesAction.TRIGGER:
    case getImageFormatAttrTypesAction.SUCCESS:
      return listReducer(state, type, payload, state.imageFormats.listName);

    case getEditImageProductionsAction.FAILURE:
    case getEditImageProductionsAction.TRIGGER:
    case getEditImageProductionsAction.SUCCESS:
      return listReducer(state, type, payload, state.productions.listName);

    case deleteImageAction.TRIGGER:
      return { ...state, updatingEntityName: payload?.id };
    case deleteImageAction.FAILURE:
      return { ...state, updatingEntityName: '' };
    case deleteImageAction.SUCCESS: {
      const name = state.images.listName;
      return {
        ...state,
        [name]: { ...state[name], data: state[name]?.data?.filter(image => image?.id !== payload?.entity?.id) },
        updatingEntityName: '',
      };
    }

    case updateImageAction.TRIGGER:
      return { ...state, updatingEntityName: payload?.id };
    case updateImageAction.FAILURE:
      return { ...state, updatingEntityName: '' };
    case updateImageAction.SUCCESS: {
      const name = state.images.listName;
      return {
        ...state,
        [name]: { ...state[name], data: changeItemInObjectsArray(state[name]?.data, payload?.entity) },
        updatingEntityName: '',
      };
    }

    case createImagesAction.TRIGGER:
      return { ...state, updatingEntityName: ENTITY_ACTION_TYPE.NEW };
    case createImagesAction.FAILURE:
      return { ...state, updatingEntityName: '' };
    case createImagesAction.SUCCESS: {
      const name = state.images.listName;
      return {
        ...state,
        [name]: { ...state[name], data: [...payload, ...state[name]?.data] },
        updatingEntityName: '',
      };
    }

    default:
      return state;
  }
};

export default editImagesReducer;

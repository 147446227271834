import React from 'react';
import PropTypes from 'prop-types';
import cx from 'src/classnames';
import styles from './H1.module.scss';

const H1 = ({ children, className }) => <h1 className={cx(styles.root, className)}>{children}</h1>;

H1.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};

export default H1;

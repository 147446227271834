/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import classes from './ButtonTabs.module.scss';

const ButtonTabs = ({ tabs, value, onClick, styles }) => (
  <div className={classnames(classes.buttonTabs, styles)}>
    {tabs.map(tab => (
      <div
        className={classnames(classes.buttonTabs__item, { [classes.active]: tab === value })}
        onClick={() => onClick(tab)}
        key={tab}
      >
        {tab}
      </div>
    ))}
  </div>
);

ButtonTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.string,
};

export default ButtonTabs;

// TODO: FB-25982 Remove separate component for this, add to home page itself
import React from 'react';
import SectionBlock from 'components/Globals/SectionBlock';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { TertiaryButton } from 'components/uiLibrary/LinkButton';
import ProductionListing, { VIEW_TYPES } from 'components/Productions/Common/ProductionListing';

import queries from 'containers/Globals/queries';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { createDate } from 'utils/date';
import { useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';
import { TP, DATE_FORMATS, PRODUCTION_VALIDITY_IDENTIFIERS, BASE_PAGE_ROUTES } from 'constants/index';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import classes from './PerformancesToday.module.scss';

const upcomingPerformanceTrackingDataSection = {
  section: SECTIONS.UPCOMING_PERFORMANCES,
  component: COMPONENTS.PRODUCTION_SLUG,
};

const seeAllPerformanceTrackingData = {
  section: SECTIONS.UPCOMING_PERFORMANCES,
  component: COMPONENTS.SEE_ALL_CTA,
  meta: {
    tab: 'today',
  },
};

const seeAllUpcomingPerformanceTrackingData = {
  ...seeAllPerformanceTrackingData,
  meta: {
    tab: 'upcoming',
  },
};

const PerformancesToday = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const { data } = useQuery(queries.getHomeNumbers());

  const today = createDate(new Date()).format(DATE_FORMATS.FULL_DATE);
  const next30Days = createDate(new Date())
    .add(30, 'day')
    .format(DATE_FORMATS.FULL_DATE);

  return (
    <SectionBlock
      title={t(`${TP}.FN_PERFORMANCES_TODAY`)}
      linkProps={navigate.getLinkProps({ baseRoute: BASE_PAGE_ROUTES.PRODUCTIONS })}
      hideSeparator
      className={classes.sectionBlock}
      count={data?.data?.performances_today?.total}
      seeAllTrackingData={seeAllPerformanceTrackingData}
    >
      <ProductionListing
        viewMode={VIEW_TYPES.PERFORMANCE}
        filters={{
          date_from: today,
          date_to: today,
          is_producer_approved: true,
          approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
        }}
        limit={isMobile ? 3 : 15}
        infinite={false}
        trackingData={upcomingPerformanceTrackingDataSection}
        upcoming
      />
      <TertiaryButton
        shape="rectangle"
        rightIcon={<SpriteIcon icon="chevron_right" className={classes.icon} />}
        {...navigate.getLinkProps({
          baseRoute: BASE_PAGE_ROUTES.PRODUCTIONS,
          queryParams: {
            date_from: today,
            date_to: next30Days,
            is_producer_approved: true,
            approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
          },
          onlyLinkProps: true,
        })}
        isLink
        onClick={() => navigate.scrollTo({ behavior: 'auto' })}
        styles={{ root: classes.seeAllPerformances }}
        trackingData={seeAllUpcomingPerformanceTrackingData}
      >
        {t(`${TP}.SEE_ALL_UPCOMING_PERFORMANCE`)}
      </TertiaryButton>
    </SectionBlock>
  );
};

export default PerformancesToday;

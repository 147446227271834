import React from 'react';
import Typography from 'components/uiLibrary/Typography';
import { TP } from 'constants/index';
import { useTranslation } from 'src/i18n';

// TODO: Not available in the design need to check with the designer

const LsVodLanguage = ({ production }) => {
  const { t } = useTranslation();
  const { languages, subtitles } = production;
  const subtitlesString = subtitles && subtitles.map(item => item.name).join(', ');
  const languagesString = languages && languages.map(item => item.name).join(', ');

  if (!languages?.[0]?.name && !subtitles?.[0]?.name) {
    return null;
  }

  return (
    <>
      {languagesString && (
        <>
          <Typography size="12" color="secondary">
            {t(`${TP}.PERFORMED_IN`)}:{' '}
          </Typography>
          <Typography size="12" weight="medium" color="secondary">
            {languagesString}
          </Typography>
        </>
      )}
      {subtitlesString && (
        <>
          <Typography size="12" color="secondary">
            {t(`${TP}.FN_SUBTITLES`)}:{' '}
          </Typography>
          <Typography size="12" weight="medium" color="secondary">
            {subtitlesString}
          </Typography>
        </>
      )}
    </>
  );
};

export default LsVodLanguage;

import React, { memo } from 'react';
import classnames from 'classnames';

import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import PreviewCard, { SIZE } from 'components/Globals/PreviewCard';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import NoResultsComponent from 'components/Search/SearchIndexPage/NoResultsComponent';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Loader from 'components/uiLibrary/Loader';
import { PageFetchObserver, useInfinitePageContext } from 'components/Globals/Layout/InfiniteListPage';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';

import { TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './EntityTrendingList.module.scss';

const MemoisedEntityCard = memo(
  PreviewCard,
  (prevProps, nextProps) => prevProps.entity.id === nextProps.entity.id && prevProps.size === nextProps.size,
);

const EntityTrendingList = ({
  title,
  count,
  linkProps,
  entities,
  entityType,
  preload,
  inline,
  condensed,
  isLoading = false,
  footerContent,
  entityTrackingData,
  seeAllTrackingData,
  isInfiniteList = false,
}) => {
  const { navigate } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const { hasNextPage, maxPageCountLimitReached } = useInfinitePageContext();
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <div className={classes.root}>
      {title && (
        <div className={classes.header}>
          <LinkButton
            variant="text"
            styles={{ root: classes.titleContainer }}
            {...linkProps}
            trackingData={seeAllTrackingData}
            onClick={navigate.scrollTo}
            rightIcon={<SpriteIcon className={classes.linkIcon} icon="chevron_right" size={16} />}
            isLink
          >
            <div className={classes.title}>
              <Typography className={classes.titleText} variant="h2" size={16} weight="bold">
                {title}
              </Typography>
              {count > 0 && (
                <Typography className={classes.count} variant="span" size={12} color="secondary">
                  {count}
                </Typography>
              )}
            </div>
          </LinkButton>
          <div>
            {linkProps && (
              <LinkButton
                variant="text"
                styles={{ root: classes.viewAll }}
                {...linkProps}
                trackingData={seeAllTrackingData}
                onClick={navigate.scrollTo}
                rightIcon={<SpriteIcon className={classes.linkIcon} icon="chevron_right" size={16} />}
                isLink
              >
                <Typography size={12} weight="medium" color="link">
                  {t(`${TP}.VIEW_ALL`)}
                </Typography>
              </LinkButton>
            )}
          </div>
        </div>
      )}
      {!isLoading && (
        <div className={classnames(classes.content, { [classes.inline]: inline, [classes.condensed]: condensed })}>
          {entities?.map(entity => (
            <MemoisedEntityCard
              key={entity.id}
              entity={entity}
              entityType={entityType}
              preloadImage={preload}
              trackingData={entityTrackingData}
              size={isMobile ? SIZE.SMALL : SIZE.LARGE}
            />
          ))}
          {isInfiniteList && hasNextPage && !maxPageCountLimitReached && (
            <PageFetchObserver>
              <Loader />
            </PageFetchObserver>
          )}
          {!entities?.length && !isLoading && <NoResultsComponent query={title} />}
        </div>
      )}
      {isLoading && (
        <Skeleton className={classes.skeleton_loader} variant="rectangular" height={isMobile ? 228 : 267} />
      )}
      {footerContent && <div className={classes.footer}>{footerContent}</div>}
    </div>
  );
};

export default EntityTrendingList;

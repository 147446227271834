import { useQuery } from 'utils/react-query';
import * as API from '../API';
import { CURRENCIES } from '../../constants/endpointConstants';
import { PAGINATION_LIMIT_1000 } from './constants';

export function useCurrencies() {
  return useQuery(['GET_CURRENCIES'], () =>
    API.getList(CURRENCIES, { pagination: PAGINATION_LIMIT_1000 }).then(({ data }) => data),
  );
}
